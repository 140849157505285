var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "订单中心", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("订单类型：")]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "10px" },
                      on: {
                        change: function ($event) {
                          return _vm.animeDat(_vm.type)
                        },
                      },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    },
                    _vm._l(_vm.typeNum, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v("    "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("状态：")]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "10px" },
                      on: {
                        change: function ($event) {
                          return _vm.animeDat(_vm.status)
                        },
                      },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    },
                    _vm._l(_vm.statesnum, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("接单用户：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入接单用户" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.userName,
                      callback: function ($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("下单用户：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入下单用户" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ordersUserName,
                      callback: function ($$v) {
                        _vm.ordersUserName = $$v
                      },
                      expression: "ordersUserName",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("订单编号：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入订单编号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ordersNo,
                      callback: function ($$v) {
                        _vm.ordersNo = $$v
                      },
                      expression: "ordersNo",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("商家名称：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入商家名称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "5px", display: "inline-block" } },
                [
                  _c("span", [_vm._v("开始时间：")]),
                  _c("el-date-picker", {
                    staticStyle: { width: "160px", "margin-left": "10px" },
                    attrs: {
                      align: "right",
                      type: "datetime",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择开始时间",
                    },
                    model: {
                      value: _vm.startTime,
                      callback: function ($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime",
                    },
                  }),
                  _vm._v("    "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "5px", display: "inline-block" } },
                [
                  _c("span", [_vm._v("截止时间：")]),
                  _c("el-date-picker", {
                    staticStyle: { width: "160px", "margin-left": "10px" },
                    attrs: {
                      align: "right",
                      type: "datetime",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择截止时间",
                    },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans },
                },
                [_vm._v("重置 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "warning", icon: "document" },
                  on: { click: _vm.exportBtn },
                },
                [_vm._v(" 导出Excel ")]
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { color: "orange" } }, [
            _vm._v(
              " * 导出订单提示：导出订单前请进行时间或者状态等筛选，否则导出订单量过多易出现卡顿或系统崩溃"
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "ordersId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "接单用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("span", [_vm._v(" - ")])
                          : _c("span", { staticStyle: { color: "#009900" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "下单用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ordersType == 1
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#008000",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row.userId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ordersUserName))]
                            )
                          : _vm._e(),
                        scope.row.ordersType == 2
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#008000",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row.userId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ordersUserName))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "商家名称", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ordersNo", label: "订单编号", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ordersType", label: "订单类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ordersType == 1
                          ? _c("span", [_vm._v("服务订单")])
                          : _vm._e(),
                        scope.row.ordersType == 2
                          ? _c("span", [_vm._v("会员订单")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "myLevel", label: "标题", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "oldMoney", label: "发布价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "普通用户价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "memberMoney", label: "会员价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "rate", label: "商家佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.rate ? scope.row.rate : "0")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "zhiRate", label: "一级佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#DD0000",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.zhiUserId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.zhiRate ? scope.row.zhiRate : "0"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "feiRate", label: "二级佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#DD0000",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.feiUserId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.feiRate ? scope.row.feiRate : "0"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "pingRate", label: "平台金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.pingRate ? scope.row.pingRate : "0"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNumber", label: "时长", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "unit", label: "单位", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "couponMoney", label: "优惠金额", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.couponMoney)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payMoney", label: "支付金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.payMoney)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "detailsAddress",
                  label: "服务信息",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v("联系人姓名：" + _vm._s(scope.row.name)),
                        ]),
                        _c("div", [
                          _vm._v("联系人联系：" + _vm._s(scope.row.phone)),
                        ]),
                        _c("div", [
                          _vm._v(
                            "服务地址：" +
                              _vm._s(scope.row.province) +
                              _vm._s(scope.row.city) +
                              _vm._s(scope.row.detailsAddress) +
                              " " +
                              _vm._s(scope.row.district) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "上门时间", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "600" },
                          },
                          [_vm._v(_vm._s(scope.row.startTime))]
                        ),
                        scope.row.state != 2 && scope.row.state != 3
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px" },
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateBz(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "备注", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.remarks))]),
                        scope.row.state != 2 && scope.row.state != 3
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px" },
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateBz(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "确认码" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startServiceTime", label: "服务开始时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endServiceTime", label: "服务结束时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startServiceImg",
                  label: "服务开始前图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.startServiceImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.startServiceImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endServiceImg",
                  label: "服务结束后图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.endServiceImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.endServiceImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startAddress",
                  label: "服务开始地址",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endAddress",
                  label: "服务结束地址",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "startServiceLongitude", label: "服务开始经度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startServiceLatitude", label: "服务开始纬度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endServiceLongitude", label: "服务结束经度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endServiceLatitude", label: "服务结束纬度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "订单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("待支付"),
                            ])
                          : _vm._e(),
                        scope.row.state == 1
                          ? _c("span", { staticStyle: { color: "#E87D0D" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        scope.row.state == 2
                          ? _c("span", { staticStyle: { color: "#009900" } }, [
                              _vm._v("已完成"),
                            ])
                          : _vm._e(),
                        scope.row.state == 3
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已取消"),
                            ])
                          : _vm._e(),
                        scope.row.state == 4
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appealFinishReason",
                  label: "申诉原因",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appealFinishRejectReason",
                  label: "驳回原因",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  fixed: "right",
                  label: "操作",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.bufeiBtn(scope.row)
                              },
                            },
                          },
                          [_vm._v("补费列表 ")]
                        ),
                        scope.row.state === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("orderCenter:wancheng"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.wancheng(scope.row)
                                  },
                                },
                              },
                              [_vm._v("完成")]
                            )
                          : _vm._e(),
                        scope.row.state === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  disabled: !_vm.isAuth("orderCenter:tuikuan"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tuikuanBtn(scope.row)
                                  },
                                },
                              },
                              [_vm._v("退款")]
                            )
                          : _vm._e(),
                        scope.row.state === 4
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                  disabled: !_vm.isAuth("orderCenter:tuikuan"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.quxiaoBtn(scope.row)
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("orderCenter:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deletes(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        scope.row.appealFinishReason
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateStatus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改订单状态")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 本页累计总金额统计：" +
                  _vm._s(_vm.totalMoney.toFixed(2)) +
                  "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 一级佣金统计：" + _vm._s(_vm.totalMoneyS.toFixed(2)) + "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 二级佣金统计：" + _vm._s(_vm.totalMoneyTy.toFixed(2)) + "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 平台佣金统计：" + _vm._s(_vm.totalMoneyP.toFixed(2)) + "元"
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补费列表",
            visible: _vm.dialogFormVisible,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading2,
                  expression: "tableDataLoading2",
                },
              ],
              attrs: { data: _vm.userData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "recordId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.userName) },
                          on: {
                            click: function ($event) {
                              return _vm.updates(scope.row.userId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payMoney", label: "补费金额" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "实际到账金额" },
              }),
              _c("el-table-column", {
                attrs: { prop: "rideName", label: "接收用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.rideName) },
                          on: {
                            click: function ($event) {
                              return _vm.updates(scope.row.rideId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payWay", label: "支付方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payWay == 1
                          ? _c("span", [_vm._v("零钱")])
                          : _vm._e(),
                        scope.row.payWay == 2
                          ? _c("span", [_vm._v("微信")])
                          : _vm._e(),
                        scope.row.payWay == 3
                          ? _c("span", [_vm._v("支付宝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("span", [_vm._v("待支付")])
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c("span", [_vm._v("已支付")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("已取消")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改备注时间",
            visible: _vm.dialogFormVisibleBz,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleBz = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("预约日期：")]
              ),
              _c("el-date-picker", {
                staticStyle: { width: "50%" },
                attrs: {
                  align: "right",
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择开始时间",
                },
                model: {
                  value: _vm.serveTime,
                  callback: function ($$v) {
                    _vm.serveTime = $$v
                  },
                  expression: "serveTime",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("预约时间：")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "200px",
                  position: "relative",
                  bottom: "30px",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.times,
                      callback: function ($$v) {
                        _vm.times = $$v
                      },
                      expression: "times",
                    },
                  },
                  _vm._l(_vm.yuyueTimeDime, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticStyle: { margin: "3px" },
                        attrs: { label: item.serviceTime, border: "" },
                      },
                      [_vm._v(_vm._s(item.serviceTime))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                    position: "relative",
                    top: "-70px",
                  },
                },
                [_vm._v("备注：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "textarea", rows: 4, placeholder: "请输入备注" },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisibleBz = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.replyNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            width: "30%",
            visible: _vm.showUpdateStatus,
            "label-position": "right",
            "label-width": "180px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUpdateStatus = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.statusForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否通过商家申诉：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusForm.auditResult,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusForm, "auditResult", $$v)
                        },
                        expression: "statusForm.auditResult",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.statusForm.auditResult == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "修改订单状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择订单状态" },
                          model: {
                            value: _vm.region,
                            callback: function ($$v) {
                              _vm.region = $$v
                            },
                            expression: "region",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "已完成", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.statusForm.auditResult == "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "驳回原因：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "auto" },
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.statusForm.rejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.statusForm, "rejectReason", $$v)
                          },
                          expression: "statusForm.rejectReason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showUpdateStatus = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureFun } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }